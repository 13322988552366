<template>
  <div class="about-info min-main">
    <Header></Header>
    <div class="banner">
      <img
        class="banner-bg min-main"
        src="@/assets/img/pc/about/banner.png"
      >
      <div class="banner-title">
        <h2>关于我们</h2>
        <div></div>
      </div>
    </div>
    <main>
      <div class="nav-title width_fixed">
        <h1>关于我们</h1>
        <h4>未讯科技是一家旨在为用户带来普惠、绿色、可持续服务的创新型科技企业。起步于2016年，前身为霖梓科技，2020年正式更名为未讯科技。</h4>
      </div>
      <div class="about-main width_fixed" style="margin-bottom: 200px;">
        <div class="left">
          <template v-for="(item, index) in leftItem">
            <div class="line" :class="item.className"></div>
            <div class="content" :class="`content-${index}`">
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <img v-show="item.imgUrl" :src="item.imgUrl" class="tree-img">
          </template>
        </div>
        <div class="line">
          <div class="line-point-start"></div>
          <div class="line-point-end"></div>
        </div>
        <div class="right">
          <template v-for="(item, index) in rightItem">
            <img v-if="item.imgUrl" :src="item.imgUrl" class="tree-img">
            <div v-if="item.title || item.text" class="line" :class="item.className"></div>
            <div v-if="item.title || item.text" class="content">
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            
          </template>
        </div>
      </div>
      <!-- <div class="reliable ">
        <div class="reliable-main nav-title width_fixed">
          <h1>可靠的合作伙伴</h1>
          <h4>作为一家长期坚持在金融科技领域的公司，未讯科技深刻理解恪守底线对于长期稳定经营的重要性。多年来，未讯科技始终活跃在金融科技领域，与广大的合作伙伴保持着长期、共赢的合作状态。</h4>
        </div>
      </div>
      <div class="segment width_fixed  nav-title ">
        <h1>部分合作伙伴</h1>
        <div class="segment-main">
          <div
            v-for="(arr,index) in logosList"
            :key="index"
          >
            <img
              v-for="(item, indexTag) in arr"
              :src="item"
              :key="index+'-'+indexTag"
            />
          </div>
        </div>
      </div> -->
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/pc/header'
import Footer from '@/components/pc/footer'

export default {
  data () {
    return {
      leftItem: [
        { title: '2022年12月', text: '未讯科技与朴道征信建立深度合作', imgUrl: require('@/assets/img/pc/about/tree_img2107.png') },
        { title: '2021年4月', text: '未讯科技成为中国互联网金融协会会员', imgUrl: require('@/assets/img/pc/about/tree_img2103.png') },
        { title: '2021年2月', text: '“易借”业务拓展升级，推出金融科技平台“易借速贷”，进一步推进普惠金融服务' },
        { title: '2019年5月', text: '百凌金科斩获零壹财经评选的“金融科技潜力50强”奖项', className: 'mt-320' },
        { title: '2018年12月', text: '百凌金科与中国联通合作项目“易借”上线', imgUrl: require('@/assets/img/pc/about/tree_img1805.png'), className: 'mt-320' },
        { title: '2018年2月', text: '“百凌金科”场景金融项目启动，助力金融机构向新金融转型升级。打造数据驱动决策的金融科技开放平台', imgUrl: require('@/assets/img/pc/about/tree_img1709.png') },
        { title: '2016年8月', text: '霖梓科技正式成立，首次推出智能信贷服务' }
      ],
      rightItem: [
        { title: '2021年7月', text: '未讯科技获得信息系统安全等级保护三级认证', imgUrl: require('@/assets/img/pc/about/tree_img2212.png') },
        { title: '2021年3月', text: '霖梓科技正式更名未讯科技', imgUrl: require('@/assets/img/pc/about/tree_img2104.png') },
        { title: '2020年6月', text: '霖梓科技与锡商银行、马上金融开展合作', imgUrl: require('@/assets/img/pc/about/tree_img2102.png') },
        { title: '2019年2月', text: '霖梓科技与大兴安岭银行开展合作', imgUrl: require('@/assets/img/pc/about/tree_img1905.png') },
        { title: '2018年5月', text: '“百凌金科”为近80家场景平台提供定制化金融产品', imgUrl: require('@/assets/img/pc/about/tree_img1812.png') },
        { title: '2017年9月', text: '霖梓科技完成5000万融资', className: 'mt-270' },
        { title: '', text: '', imgUrl: require('@/assets/img/pc/about/tree_img1608.png') }
      ],
      logosList: [
        [
          require('@/assets/img/pc/about/logo1.png'),
          require('@/assets/img/pc/about/logo2.png'),
          require('@/assets/img/pc/about/logo3.png'),
          require('@/assets/img/pc/about/logo4.png'),
          require('@/assets/img/pc/about/logo5.png')
        ]
        [
          require('@/assets/img/pc/about/logo6.png'),
          require('@/assets/img/pc/about/logo7.png'),
          require('@/assets/img/pc/about/logo8.png'),
          require('@/assets/img/pc/about/logo9.png'),
          require('@/assets/img/pc/about/logo10.png')
        ],
        [
          require('@/assets/img/pc/about/logo11.png'),
          require('@/assets/img/pc/about/logo12.png'),
          require('@/assets/img/pc/about/logo13.png'),
          require('@/assets/img/pc/about/logo14.png'),
          require('@/assets/img/pc/about/logo15.png')
        ]
      ]
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="less">
.about-info {
  .banner {
    position: relative;
    .banner-bg {
      left: 0;
      top: 0;
      height: auto;
      width: 100%;
      min-width: 1300px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &-title {
      width: 1300px;
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translateX(-50%);
      h2 {
        font-size: 32px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        letter-spacing: 2px;
      }
      div {
        margin-top: 20px;
        width: 60px;
        height: 3px;
        background: #ffffff;
      }
    }
  }
  main {
    background-color: white;
    .nav-title {
      margin-top: 80px;
      h1 {
        font-size: 30px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        text-align: center;
        color: #1e1f20;
        line-height: 40px;
      }
      h4 {
        margin-top: 16px;
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: center;
        color: #4f4f4f;
        line-height: 35px;
      }
    }
    .reliable {
      width: 100%;
      margin-top: 83px;
      background-color: #fbfcff;
      display: flex;
      justify-items: cente;
      h4 {
        width: 1174px;
        text-align: left;
        margin-left: 63px;
      }
      &-main {
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 1300px;
        height: 700px;
        background-image: url("~@/assets/img/pc/about/reliable-bg.png");
      }
    }
    .segment {
      margin-bottom: 80px;
      &-main {
        margin-top: 53px;
        div {
          display: flex;
          justify-content: space-between;
          width: 1300px;
          margin-bottom: 30px;
          img {
            width: 240px;
            height: 92px;
          }
        }
      }
    }
    .about-main {
      display: flex;
      margin-top: 62px;
      h3 {
        font-size: 22px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        color: #1e1f20;
        line-height: 30px;
      }
      p {
        margin-top: 17px;
        font-size: 20px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 34px;
      }

      .left {
        flex: 1;
        display: block;
        padding-top: 70px;
        text-align: right;
        & > img,
        & > .line {
          display: inline-block;
        }
        .tree-img {
          width: 402px;
          height: 242px;
          margin-right: 80px;
          margin-bottom: 38px;
        }
        .content {
          height: 260px;
          padding-top: 20px;
          margin-right: 80px;
        }
        .line {
          height: 2px;
          width: 193px;
          background-color: #ee6c28;
        }
        .mt-320 {
          margin-top: 320px;
        }
        // .line2 {
        //   margin-top: 320px;
        // }
        // .line3 {
        //   margin-top: 315px;
        // }
      }
      .line {
        width: 2px;
        background-color: #ee6c28;
        position: relative;
        .line-point-start {
          position: absolute;
          left: -2px;
          top: -3px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #ee6c28;
        }
        .line-point-end {
          position: absolute;
          left: -2px;
          bottom: -3px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #ee6c28;
        }
      }
      .right {
        flex: 1;
        padding-top: 80px;
        .line {
          margin-bottom: 30px;
          height: 2px;
          width: 193px;
          background-color: #ee6c28;
        }
        .mt-270 {
          margin-top: 270px;
        }
        .content {
          height: 260px;
          padding-top: 20px;
          margin-left: 80px;
        }
        .tree-img {
          width: 402px;
          height: 242px;
          margin-left: 80px;
          margin-bottom: 38px;
        }
      }
    }
  }
}
</style>
